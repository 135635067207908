








































































.account-page {
  width: 100%;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 0;
    min-height: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 10px 2px #00000038;
    .navbar-link {
      width: 100%;
      max-width: 200px;
      min-width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        a {
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
        }
      }
      a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        &.active {
          border-bottom: 3px solid #009751;
          background: linear-gradient(89deg, #00975146, #8ec63f75);
        }

        .text {
          color: #23222f;
          font-weight: 600;
          font-size: 20px;
          text-transform: uppercase;
        }
        .icon {
          img {
            height: 20px;
            width: auto;
          }
        }
      }
    }
  }
  .content-box {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 0;
  }
}
